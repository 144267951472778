
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































































.flexible-quote {
  // Homepage fix
  .cpt-assert + .cpt-quote & {
    margin-top: 0;
  }

  @include mq($until: m) {
    ::v-deep em {
      font-size: 2.3rem;
    }
  }

  @include mq(l) {
    padding-right: col(1, 10);
    padding-left: col(1, 10);
    text-align: center;
  }
}

.flexible-quote__link {
  @include default-margin(0.5);
}

.flexible-quote__text {
  @include default-margin(0.5);

  text-align: left;

  @include mq(s) {
    padding: 0 col(1, 10);
  }

  @include mq(m) {
    padding: 0 col(2, 10);
  }
}
